import React, { Component } from 'react';
import './StatusMessage.css';
import PropTypes from 'prop-types';

export default class StatusMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.active !== this.state.active) {
      this.setState({ active: nextProps.active });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="floatingWindow" style={{ display: this.state.active ? 'inline' : 'none'}}>
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

StatusMessage.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.object
};
