import React, { Component } from 'react';
import LandingMessage from './LandingMessage';
import FileWindowDirectory from './FileWindowDirectory.jsx';
import Blurb from './Blurb';
import TextCopier from './TextCopier';
import { Button, Container, Row, Col, Spinner } from 'reactstrap';
import './FileWindow.css';
import PropTypes from 'prop-types';

import EnvironmentConfiguration from '../config.js';

var config = EnvironmentConfiguration.getConfig();
var S3;

// eslint-disable-next-line no-unused-vars
var contents = {};

export default class FileWindow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      prefix: this.props.prefix,
      loading: false,
      bucket: this.props.bucket,
      domain: '',
      files: {}
    };
  }
  closeProjectWindow = () => {
    this.props.closeProject();
    contents = {};
    this.setState({ load: false });
  };

  async UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.bucket !== this.state.bucket) {
      this.setState({ bucket: nextProps.bucket }, () => {
        if (this.state.bucket === config.s3.LIVE) {
          this.setState({ domain: config.s3.LIVE_DOMAIN });
        }
      });
    }
    if (nextProps.open !== this.state.open || nextProps.prefix !== this.state.prefix) {
      this.setState({ open: nextProps.open, prefix: nextProps.prefix });
    }

    if (this.state.open === false && nextState.open === true) {
      this.onOpen();
    }
  }

  async componentDidMount() {
    S3 = this.props.s3;
    var prefix = this.state.prefix;
    var files = {};
    files[prefix] = [];
    var domain = '';
    if (this.state.bucket === config.s3.LIVE) {
      domain = config.s3.LIVE_DOMAIN;
    }

    this.setState({ domain: domain });
  }

  onOpen = async () => {
    this.setState({ loading: true }, async () => {
      var files = await this.recursiveTree(this.state.prefix);
      this.setState({ files: files, loading: false });
    });
  };

  recursiveTree = async (prefix) => {
    var directory = {};
    directory[prefix] = [];
    var files = directory[prefix];
    await S3.listObjectsV2({
      Bucket: this.state.bucket,
      Delimiter: '/',
      Prefix: prefix
    })
      .promise()
      .then(async (data) => {
        if (data) {
          if ('Contents' in data) {
            for (var i = 0; i < data.Contents.length; i++) {
              files.push(data.Contents[i].Key);
            }
          }

          if ('CommonPrefixes' in data) {
            // eslint-disable-next-line no-redeclare
            for (var i = 0; i < data.CommonPrefixes.length; i++) {
              if (data.CommonPrefixes[i].Prefix === prefix) {
                continue;
              }
              files.push(await this.recursiveTree(data.CommonPrefixes[i].Prefix));
            }
          }
        }
      });

    return directory;
  };

  renderDir = (dir = this.state.files) => {
    var evenRowColor = 'gainsboro';
    var oddRowColor = 'white';

    var elementList = [];

    if ((Object.keys(dir).length === 0 && dir.constructor === Object) || !this.state.open) {
      return;
    }

    for (var i = 0; i < Object.keys(dir).length; i++) {
      var key = Object.keys(dir)[i];
      var contents = dir[key];
      var count = 1;
      for (var n = 0; n < contents.length; n++) {
        var rowColor = n % 2 === 0 ? evenRowColor : oddRowColor;
        var content = contents[n];
        if (typeof content === 'string') {
          var fileName = content.split('/')[content.split('/').length - 1];
          elementList.push(
            <Row
              key={content}
              style={{
                justifyContent: 'left',
                backgroundColor: rowColor,
                transition: 'background-color 0.5s, color 0.5s'
              }}>
              <Col xs={{ size: 'auto' }}>
                <h4 style={{ marginLeft: count + 'rem' }}>{fileName}</h4>
              </Col>
              <Col>
                <TextCopier
                  style={{ marginLeft: '50%' }}
                  message={'Successfully copied url to the clipboard!'}
                  text={this.state.domain + '/' + content}
                />
              </Col>
            </Row>
          );
        } else if (typeof content === 'object') {
          elementList.push(
            <FileWindowDirectory
              key={Object.keys(content)[0]}
              domain={this.state.domain}
              directory={content}
              offset={count}
              evenRowColor={evenRowColor}
              oddRowColor={oddRowColor}
              root={this.state.prefix}
            />
          );
          count++;
        }
      }
    }

    return elementList;
  };

  render() {
    if (this.props.prefix === '') {
      return <React.Fragment />;
    }
    return (
      <React.Fragment>
        {this.state.open ? (
          <React.Fragment>
            <LandingMessage active={this.state.open} close={this.closeProjectWindow}>
              <Row>
                <Col xs="auto">
                  <h2>{this.state.prefix}</h2>
                </Col>
              </Row>
              <Container
                style={{
                  maxHeight: '30vw',
                  minWidth: '80vw'
                }}>
                {this.state.loading ? (
                  <Row style={{ marginTop: '10%' }}>
                    <Col>
                      <Blurb>
                        <Spinner /> Loading your files...
                      </Blurb>
                    </Col>
                  </Row>
                ) : (
                  this.renderDir()
                )}
              </Container>
            </LandingMessage>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }
}

FileWindow.propTypes = {
  bucket: PropTypes.object,
  prefix: PropTypes.object,
  open: PropTypes.bool,
  s3: PropTypes.object,
  closeProject: PropTypes.func,
  setModalOpen: PropTypes.func
};
