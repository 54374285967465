import React, { useState, useEffect } from 'react';
import ProjectCard from './ProjectCard';
import FileCard from './FileCard';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './CourseDisplay.css';
import EnvironmentConfiguration from '../config.js';

const config = EnvironmentConfiguration.getConfig();

const CourseDisplay = ({ courseName, s3, bucket, fileNeedToBeLive, setModalOpen, searchText }) => {
  const [filteredProjects, setFilteredProjects] = useState([]);

  const updateListing = () => {
    s3.listObjectsV2(
      {
        Bucket: bucket,
        Delimiter: '/',
        Prefix: courseName
      },
      (err, data) => {
        if (err || !data) return;

        const newProjects = [];
        data.Contents.forEach((file) => {
          newProjects.push({ prefix: file.Key, type: 'file', deploying: false });
        });

        data.CommonPrefixes.forEach((projectPrefix) => {
          newProjects.push({ prefix: projectPrefix.Prefix, type: 'project' });
        });

        setFilteredProjects(newProjects);

        if (searchText) {
          const filtered = newProjects.filter((proj) =>
            proj.prefix.split('/')[1].toLowerCase().includes(searchText.toLowerCase())
          );
          setFilteredProjects(filtered);
        }

      }
    );
  };

  useEffect(() => {
    updateListing();
  }, [courseName, s3, bucket, fileNeedToBeLive, searchText]);

  return (
    <>
      {filteredProjects.length > 0 ? (
        <Accordion>
          <Accordion.Item eventKey="0" style={{ border: '0px' }}>
            <Accordion.Header>
              <h4>
                <i>{courseName}</i>
              </h4>
            </Accordion.Header>
            <Accordion.Body style={{ paddingLeft: '0px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'start'
                }}>
                {filteredProjects.map((proj) => (
                  <div
                    style={{
                      paddingLeft: '2px',
                      paddingRight: '2px',
                      height: 'inherit',
                      width: '20%'
                    }}
                    key={'root-files-' + proj.prefix}>
                    {proj.type === 'file' ? (
                      <FileCard file={proj.prefix} bucket={bucket} />
                    ) : (
                      <ProjectCard
                        setModalOpen={setModalOpen}
                        s3={s3}
                        project={proj.prefix}
                        bucket={bucket}
                        deploying={proj.deploying}
                      />
                    )}
                  </div>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ) : (
        <div></div>
      )}
    </>
  );
};

CourseDisplay.propTypes = {
  courseName: PropTypes.string.isRequired,
  s3: PropTypes.object.isRequired,
  bucket: PropTypes.string.isRequired,
  rowLength: PropTypes.number,
  fileNeedToBeLive: PropTypes.array,
  setModalOpen: PropTypes.func,
  searchText: PropTypes.string
};

export default CourseDisplay;
