import React from 'react';
import LandingMessage from '../components/LandingMessage.jsx';
import StatusMessage from '../components/StatusMessage.jsx';
import Title from '../components/PageTitle.jsx';
import Blurb from '../components/Blurb.jsx';
import Library from '../components/Library.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import Status from '../components/Status.jsx';
import Upload from '../components/Upload.jsx';
import Rerenderer from '../components/Rerenderer.jsx';
import { Container, Button } from 'reactstrap';
import MainNavbar from '../components/Navbar';
import { Hub, Cache } from 'aws-amplify';
import PropTypes from 'prop-types';

import EnvironmentConfiguration from '../config';

var config = EnvironmentConfiguration.getConfig();

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      landingMessage: true,
      isAuthenticated: this.props.props.isAuthenticated,
      uploadModalOpen: false,
      status: false,
      canUpload: true,
      bucket: config.s3.LIVE,
      dropdownOpen: false,
      fileNeedTobeLive: [],
      pipelineStage: 'None'
    };
  }

  setUploadModalOpen = (isOpen) => {
    this.setState({ uploadModalOpen: isOpen });
  };

  stageChanged = (newStage) => {
    this.setState({ pipelineStage: newStage });
  };

  setUploadState = (response) => {
    if (response !== this.state.canUpload) {
      this.setState({ canUpload: response });
    }
  };

  getFileNeedTobeLive = (resp) => {
    this.setState({ fileNeedTobeLive: resp });
  };

  getCognitoUser = async () => {
    await this.props.props.auth
      .currentAuthenticatedUser({
        bypassCache: false
      })
      .then((user) => {
        var name =
          user.signInUserSession.idToken.payload.given_name &&
          user.signInUserSession.idToken.payload.family_name
            ? `${user.signInUserSession.idToken.payload.given_name} ${user.signInUserSession.idToken.payload.family_name}`
            : null;
        Cache.setItem('Name', name);
        this.props.props.userHasAuthenticated(true);
      })
      .catch(() => {
        this.props.props.userHasAuthenticated(false);
        this.redirect('/login');
      });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.props.isAuthenticated !== this.state.isAuthenticated) {
      console.log(this.state);
      if (nextProps.props.isAuthenticated === false) {
        this.getCognitoUser();
      }
    }
  }

  componentDidMount() {
    if (
      window.localStorage.getItem('isOffice365Authenticating') === 'false' ||
      !window.localStorage.getItem('isOffice365Authenticating')
    ) {
      this.getCognitoUser();
    } else {
      window.setTimeout(() => {
        if (
          !this.state.isAuthenticated &&
          window.localStorage.getItem('isOffice365Authenticating') === 'true'
        ) {
          this.props.props.userHasAuthenticated(false);
          window.localStorage.setItem('isOffice365Authenticating', false);
          this.redirect('/login');
        }
      }, 3000);
      Hub.listen('auth', (data) => {
        switch (data.payload.event) {
          case 'cognitoHostedUI':
            break;
          case 'signIn':
            this.getCognitoUser();
            break;
          case 'signIn_failure':
            break;
          default:
            this.redirect('/login');
            break;
        }
      });
    }
  }

  componentWillUnmount() {
    Hub.remove('auth');
  }

  redirect = (location) => {
    this.props.navigate(location, {
      state: {
        isAuthenticated: this.props.props.isAuthenticated,
        courses: this.props.props.courses
      }
    });
  };

  toggleWindow = () => {
    this.setState({ landingMessage: !this.state.landingMessage });
  };

  render() {
    if (!this.props.props.isAuthenticated) {
      return <React.Fragment />;
    }
    var childProps = { ...this.props.props };
    return (
      <React.Fragment>
        <MainNavbar {...childProps} brandname="CIPC File Uploader">
          <span title="Upload a project">
            <Button
              aria-label="Upload button"
              color="primary"
              onClick={() => this.setUploadModalOpen(true)}
              disabled={!this.state.canUpload}>
              Upload
            </Button>
          </span>
        </MainNavbar>
        <Rerenderer seconds={2} style={{ backgroundColor: 'blue' }}>
          {this.state.uploadModalOpen ? (
            <LandingMessage 
              close={this.setUploadModalOpen}
              active={true}
              style={{ backgroundColor: 'blue' }}>
              <Upload
                style={{ backgroundColor: 'blue' }}
                {...this.props.props}
                setUploadState={this.setUploadState}
                canUpload={this.state.canUpload}
              />
            </LandingMessage>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <Container id="content">
            <Title style={{ width: '100%' }}>
              <h1>
                The <span className="highlight">CIPC File Uploader</span>
              </h1>
            </Title>
            <Blurb>CIPC Module Library</Blurb>

            {this.props.props.isAuthenticated ? (
              <Library
                {...childProps}
                style={{ zIndex: '-1' }}
                fileNeedTobeLive={this.state.fileNeedTobeLive}
                pipelineStage={this.state.pipelineStage}></Library>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Container>
        </Rerenderer>
      </React.Fragment>
    );
  }
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    return <Component location={location} navigate={navigate} {...props} />;
  }
  return ComponentWithRouterProp;
}

Home.propTypes = {
  props: PropTypes.bool,
  navigate: PropTypes.object
};

export default withRouter(Home);
