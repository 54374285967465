import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { Card, Button } from 'reactstrap';
import EnvironmentConfiguration from '../config';
var config = EnvironmentConfiguration.getConfig();

export default class FileCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: this.props.file,
      bucket: this.props.bucket,
      text: ''
    };
  }

  componentDidMount() {
    var bucketUrl;
    if (this.state.bucket === config.s3.LIVE) {
      bucketUrl = config.s3.LIVE_DOMAIN + '/' + this.state.file;
    }

    this.setState({ text: bucketUrl });
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.bucket !== this.state.bucket) {
      var bucketUrl;
      if (nextProps.bucket === config.s3.LIVE) {
        bucketUrl = config.s3.LIVE_DOMAIN + '/' + this.state.file;
      }
      this.setState({ bucket: nextProps.bucket, text: bucketUrl });
    }
  }

  copyText = (element) => {
    var textArea = document.createElement('textarea');
    textArea.value = this.state.text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    element.target.focus();

    toast('Successfully copied url to the clipboard!', {
      hideProgressBar: true,
      className: 'success-toast',
      bodyClassName: 'toast-body'
    });
  };

  render() {
    var heading = this.state.file.split('/')[this.state.file.split('/').length - 1];
    return (
      <React.Fragment>
        <Card
          style={{
            backgroundColor: 'gainsboro',
            opacity: this.state.deploying ? '0.7' : '1'
          }}>
          <div className="cardInner">
            <span title={this.state.file}>
              <h5
                style={{
                  height: '2rem',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}>
                {heading}
              </h5>
            </span>
            {this.props.children}
            <Button color="info" block onClick={this.copyText}>
              Copy Url
            </Button>
          </div>
        </Card>
        <form style={{ display: 'none' }}>
          <textarea ref={(textarea) => (this.textArea = textarea)} value={this.state.text} />
        </form>
      </React.Fragment>
    );
  }
}

FileCard.propTypes = {
  file: PropTypes.object,
  bucket: PropTypes.object,
  children: PropTypes.object
};
