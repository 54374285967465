import React, { Component } from 'react';
import FileWindow from './FileWindow';
import PropTypes from 'prop-types';
import { Card, Button, Spinner } from 'reactstrap';
import EnvironmentConfiguration from '../config';

export default class ProjectCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      project: this.props.project,
      s3: this.props.s3,
      viewing: this.props.viewing ? this.props.viewing : false,
      bucket: this.props.bucket,
      deploying: this.props.deploying
    };
  }

  selectProject = () => {
    this.setState({ viewing: true });
  };

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.bucket !== this.state.bucket || nextProps.deploying !== this.state.deploying) {
      this.setState({
        bucket: nextProps.bucket,
        deploying: nextProps.deploying
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card
          style={{
            backgroundColor: 'gainsboro',
            opacity: this.state.deploying ? '0.7' : '1'
          }}>
          <div className="cardInner">
            <span title={this.state.project}>
              <h5
                style={{
                  height: '2rem',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}>
                {this.props.project.split('/')[1]}
              </h5>
            </span>
            {this.props.children}
            {this.state.deploying ? (
              <Spinner style={{ margin: 'auto', marginTop: '3%' }} />
            ) : (
              <Button
                color="primary"
                block
                onClick={() => {
                  this.selectProject();
                }}>
                View Project
              </Button>
            )}
          </div>
        </Card>
        <FileWindow
          setModalOpen={this.props.setModalOpen}
          open={this.state.viewing}
          closeProject={() => {
            this.setState({ viewing: false });
          }}
          s3={this.state.s3}
          prefix={this.state.project}
          bucket={this.state.bucket}
        />
      </React.Fragment>
    );
  }
}

ProjectCard.propTypes = {
  project: PropTypes.func,
  viewing: PropTypes.object,
  s3: PropTypes.object,
  bucket: PropTypes.object,
  deploying: PropTypes.object,
  children: PropTypes.object,
  setModalOpen: PropTypes.func
};
