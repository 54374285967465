import React, { Component } from 'react';
import './LandingMessage.css';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export default class LandingMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.active !== this.state.active) {
      this.setState({ active: nextProps.active });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.active === true ? (
          <div className="floatingWindow">
            {/* Close Button */}
            <Button
              color="danger"
              onClick={() => this.props.close()}
              style={{
                position: 'absolute',
                zIndex: '999',
                top: '10px',
                right: '10px',
                fontSize: '1.5rem',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0
              }}>
              ×
            </Button>
            <div style={{ position: 'relative' }}>{this.props.children}</div>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

LandingMessage.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.object
};
