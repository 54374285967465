/* eslint-disable prettier/prettier */
/* eslint-disable no-redeclare */
import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Input,
  Container,
  Button,
  Spinner
} from 'reactstrap';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import environmentConfiguration from '../config.js';
import { lookup } from 'mime-types';

var config = environmentConfiguration.getConfig();

const Upload = ({ auth, courses }) => {
  const AWS = require('aws-sdk');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lambda, setLambda] = useState(null);
  const [s3, setS3] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newCourse, setNewCourse] = useState(false);
  const [course, setCourse] = useState(null);

  useEffect(() => {
    auth.currentCredentials().then((credentials) => {
      setLambda(new AWS.Lambda({ credentials, region: 'us-east-1' }));
      setS3(new AWS.S3({ credentials, region: 'us-east-1' }));
    });
  }, [auth]);

  const selectCourse = (courseName) => {
    courseName = courseName.replace('/', '').replace('\\', '');
    setCourse(courseName);
    setNewCourse(false);
  };

  const selectNewCourseName = (courseName) => {
    if (courseName === '') {
      setCourse(null);
      return;
    }
    courseName = courseName.replace('/', '').replace('\\', '');
    setCourse(courseName);
    setNewCourse(true);
  };

  // Handle file drop
  const onDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  // Upload function
  const uploadFiles = async () => {
    setLoading(true);
    const filesObjectKeyPath = `temp/${Date.now()}/`;
    try {
      // UPLOAD TO S3 with unique object key
      const uploadPromises = files.map(async (file) => {
        const contentType = lookup(file.name) || 'application/octet-stream'; // Fallback if unknown
        const params = {
            Bucket: config.s3.WEBAPP,
            Key: filesObjectKeyPath+file.name, 
            Body: file,
            ContentType: contentType
        };
          try {
              await s3.upload(params).promise();
              console.log(`File "${file.name}" uploaded successfully`);
          } catch (error) {
              console.error(`Error uploading file "${file.name}":`, error);
          }
      });

      // Wait for all uploads to complete
      await Promise.all(uploadPromises);
    
      // Set Lambda param payload
      const params = {
        FunctionName: config.lambda.ARN,
        Payload: JSON.stringify({ courseName: course, sourceBucket: config.s3.WEBAPP, sourceBucketPrefix: filesObjectKeyPath, destinationBucket: config.s3.LIVE }),
      };
      console.log(JSON.stringify(params))

      // Now trigger Lambda
      try {
      const response = await lambda.invoke(params).promise();
      if (response.Payload) {
        // Parse the body to access the "message"
        const parsedResp = JSON.parse(response.Payload);
        const parsedStatusCode = JSON.parse(parsedResp.statusCode);
        const parsedBody = JSON.parse(parsedResp.body);
        if (parsedStatusCode === 200) {
          toast.success(parsedBody.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            });
        } else {
          toast.error("Sorry, there was an error processing your files.", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            });
        }
        console.log('Lambda Response:', JSON.parse(response.Payload));
        }
      }
      catch (error) {
        console.error("Lambda invocation error:", error);
        toast.error("Failed to upload files. Please try again later.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
          });
      }

      // Reset files after successful upload
      setFiles([]);
    } catch (error) {
      console.error('Error uploading files:', error);
    } finally {
      setLoading(false);
    }
  };

  // Initialize React Dropzone
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: loading,
  });

  const renderDropdown = () => {
    var dropdownItems = [];
  
    // Add "Select Course" option
    dropdownItems.push(
      <DropdownItem
        key="selectCourse"
        onClick={() => {
          setCourse(null);
          setNewCourse(null);
        }}>
        Select Course
      </DropdownItem>
    );
  
    // Sort courses alphabetically before rendering
    const sortedCourses = courses.slice().sort((a, b) => a.localeCompare(b));
  
    // Add sorted course options
    sortedCourses.forEach((course) => {
      dropdownItems.push(
        <DropdownItem key={course} onClick={() => selectCourse(course)}>
          {course}
        </DropdownItem>
      );
    });
  
    // Add "Create New Course" option
    dropdownItems.push(
      <DropdownItem key="newCourse" onClick={() => (setNewCourse(true), setCourse(''))}>
        Create New Course
      </DropdownItem>
    );
  
    // Return the dropdown component
    return (
      <Dropdown direction="down" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
        <DropdownToggle style={{ width: '200px' }} caret>
          {course === null || newCourse ? 'Select Course' : course}
        </DropdownToggle>
        <DropdownMenu style={{ width: '200px' }}>{dropdownItems}</DropdownMenu>
      </Dropdown>
    );
  };

  return (
    <Container style={{
      flex: 1,
      maxHeight: '40vw'
    }}>
          <h1>
            Upload file to the <span className="highlight">CIPC File Repository</span>
          </h1>
          <Row>
            <Col>{renderDropdown()}</Col>
          </Row>
          {newCourse ? (
            <div style={{
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
              textAlign: 'center', 
              margin: '0 auto',
              paddingBottom: 15,
              marginTop: '-5px'
            }}
      >
                <Input
                  id="newCourseInput"
                  placeholder="Name of new course"
                  style={{textAlign: 'center', width: '200px'}}
                  onChange={(courseName) => {
                    selectNewCourseName(courseName.target.value);
                  }}></Input>
                  </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
      {(course || newCourse) && <>
        <div className="dropzone" style={{minWidth: '100%'}}>
          <div id="outer" {...getRootProps()} style={{padding:10}} >
          {!files.length && <h4>Drag & Drop files here, or click to select files</h4>}
          {loading && <Spinner color="primary" />}
          {loading && <h4>Uploading...</h4>}
            {!loading && <div>
            {files.length > 0 ? (
              <div  style={{maxHeight: '30vw', overflowY: 'auto', overflowX: 'hidden'}}>
                <h5>READY TO UPLOAD:</h5>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center', 
                    textAlign: 'center', 
                    maxWidth: '50%',
                    margin: '0 auto',
                  }}
                >
                <ol style={{textAlign: 'left'}}>
                {files.map((file, index) => (
                    <li key={index}>{file.name}</li>
                ))}</ol>
                </div>
              </div>
                ) : (
                  <p></p>
                )}
             </div>}
            </div>
          </div>

        <input {...getInputProps()} />
      <Button
      style={{zIndex: '999'}}
      color="danger"
      onClick={()=>setFiles([])} disabled={loading || files.length === 0}>
        Clear Files
      </Button>
      <Button 
        style={{zIndex: '999'}}
        color="primary" onClick={uploadFiles} disabled={loading || files.length === 0 || !course}>
        {loading ? 'Uploading...' : 'Upload Files'}
      </Button></>}
    </Container>
  );
};

Upload.propTypes = {
  auth: PropTypes.object.isRequired,
  courses: PropTypes.array
};

export default Upload;
